<template>
  <div></div>
</template>

<script>
// import axios from "../../../utils/axios.js";
export default {
  data() {
    return {
      schedule: [],
    };
  },
  components: {},
  mounted() {
    this.getScheduleHistory();
  },
  methods: {
    async getScheduleHistory() {
      try {
        // const {
        //   data: { data },
        // } = await axios.get(`${process.env.VUE_APP_API_URI}/order-history`);
        // console.log(data);
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>
